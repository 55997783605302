<!--
--------------------------------------------------------------------------------
<copyright file="TargetFilter.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <details>
    <summary>
      <p class="list2">{{ $t('widget_info_text.target_filter') }}</p>
    </summary>
    <p class="text list">
      {{ $t('widget_info_text.target_filter_txt') }}
      <img src="images/info/editTargets.jpg" />
    </p>
  </details>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class TargetFilterInfo extends Vue {}
</script>
